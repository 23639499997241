<template>
    <div>
        <div class="grid grid-cols-1 mt-10 bg-[#181C21] rounded-lg p-4 py-10">
                <div class="flex flex-row gap-4 items-center">
                    <picture class="aspect-square w-20 h-20 flex-none">
                        <img :src="airdrop.logoUrl" class="object-cover w-full h-full rounded-md" alt="Image header" />
                    </picture>
                    <div class="flex flex-col gap-1">

                        <div class="text-2xl md:text-4xl font-semibold text-white">
                            {{ airdrop.title }}
                        </div>
                        <div class="text-base md:text-lg text-white/70" v-html="airdrop.description">
                        </div>
                    </div>
                </div>
                <div class="flex flex-row gap-2 mt-10">
                    <a :href="airdrop.website" target="_blank"
                        class="btn text-white text-xs md:text-base transition-all duration-1000"
                        v-if="airdrop.website">Website</a>
                    <a :href="airdrop.twitter" target="_blank"
                        class="btn text-white text-xs md:text-base transition-all duration-1000"
                        v-if="airdrop.twitter">Twitter</a>
                    <a :href="airdrop.discord" target="_blank"
                        class="btn text-white text-xs md:text-base transition-all duration-1000"
                        v-if="airdrop.discord">Discord</a>
                </div>
                <hr>
                <div class="flex justify-between items-center">
                    <div class="flex flex-col gap-4">
                        <div class="text-white text-base md:text-lg">Category</div>
                        <div class="text-white text-base md:text-lg">Airdrop Likelihood</div>

                    </div>
                    <div class="flex flex-col items-end gap-4">
                        <div class="btn text-sm md:text-base text-white transition-all duration-1000">{{ airdrop.category }}
                        </div>
                        <div class="btn text-sm md:text-base text-white transition-all duration-1000">{{
                            airdrop.airdroplikehood }}</div>
                    </div>
                </div>
                <hr>
                <div class="mt-10 flex flex-col gap-2" v-html="airdrop.blog">

                </div>
            </div>
        </div>
  
</template>
<script>

export default {
    data() {
        const dataair = JSON.parse(localStorage.getItem("dataair"));
        return {
            airdrop: dataair.quest.options,
            idairdrop: dataair.quest._id,
        }
    }
}
</script>
<style>
.btn {
    font: bold;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

}

.btn:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}
</style>